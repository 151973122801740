<template>
    <div container>
        <!-- <my-header></my-header> -->
        <div spacer>
            <div inside>
                <h1 class="page-header">蓝灯鱼{{$t('legal.h1')}}</h1>
                <el-tabs :value="cur" @tab-click="handleClick">
                    <el-tab-pane :label="$t('legal.links.term')" name="term"></el-tab-pane>
                    <el-tab-pane :label="$t('legal.links.privacy')" name="privacy"></el-tab-pane>
                </el-tabs>
                <router-view></router-view>

            </div>

        </div>
      <my-footer></my-footer>
    </div>
</template>

<script>
import myFooter from "@/components/common/xfooter";
export default {
    // head() {
    //     return {
    //         title: this.$t('legal.h1') + ' | Lanternfish'
    //     }
    // },
    computed: {
        /** 计算当前激活的路由 */
        cur() {
            return this.$route.path.split('/')[2]
        }
    },
    components: {
        myFooter
    },
    methods: {
        /** tab页跳转 */
        handleClick(e) {
            this.$router.push(`/legal/${e.name}`)
        }
    },

}
</script>

<style lang='less' scoped>
[container]{
    /*min-height: 100vh;*/
    /*display: flex;*/
    /*flex-direction: column;*/
}
[spacer]{
    /*flex-grow: 1;*/
    padding: 94px 100px 30px;
}
[inside]{
    margin: 0 auto;
    max-width: 1024px;
}
</style>
